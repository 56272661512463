import {
    COOKIES
} from '../actions/cookies';

const initialState = localStorage.getItem("cookies") === "true" ? true : false;

export function set(state, action) {
    localStorage.setItem("cookies", action.payload);

    return action.payload;
}

export function cookiesReducer(state = initialState, action) {
    switch (action.type) {
        case COOKIES: return set(state, action);
        default: return state;
    }
}