export const COOKIES = 'COOKIES';

export function cookies(payload) {
    return {
        type: COOKIES,
        payload: payload
    };
}

export const setCookies = (props) => {
    return async (dispatch) => {
        dispatch(cookies(props));
    }
};