import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { combineReducers } from 'redux';
import { cookiesReducer } from './reducers/cookies';
import { youtubeReducer } from './reducers/youtube';

const rootReducer = combineReducers({
    cookies: cookiesReducer,
    youtube: youtubeReducer,
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;