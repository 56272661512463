import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";

import NavMenu from "./NavMenu";

const Header = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [scroll, setScroll] = useState(0);
    
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY);
        });
    }, []);

    const isMobile = width <= 768;

    const [showMenu, setShowMenu] = useState(false);

    const handleMenu = (e) => {
        e && e.preventDefault();
        setShowMenu(!showMenu);
    };

    return (
        <>
            <NavMenu show={showMenu} onClose={() => handleMenu(false)} />

            <header className={scroll > 800 ? "scrolled" : "scrolled--not"}>
                
            <Link to="/"><img loading="lazy" src={require("../../assets/logo9.svg").default} alt="Our logo. Osiris." className="logo" /> </Link>
               

                {
                    isMobile ?
                    <div className="user-select-none">
                        <img className="menu__option" src={require("../../assets/menu.png").default} alt="Open the menu." onClick={handleMenu} />
                    </div>
                    :
                    <div className="alegeri d-flex flex-row justify-content-center">
                        <Link to="validators" className="option">Validators</Link>
                        <Link to="faq" className="option">FAQ</Link>
                        <a href="https://docs.osiris.finance"target="/" className="option">DOCS <img className="option__image" src={require("../../assets/arrow.svg").default} /></a>
                    </div>
                }
            </header>
        </>
    );
}

export default Header;