import axios from "axios";

export const GET_VIDEOS = 'GET_VIDEOS';

export function setVideos(payload) {
    return {
        type: GET_VIDEOS,
        payload: payload
    };
}

function formatISODate(youtube_time){
    let array = youtube_time.match(/(\d+)(?=[MHS])/ig)||[]; 

    var formatted = array.map(function(item){
        if(item.length<2) return '0'+item;
            return item;
    }).join(':');

    if (array.length < 2) {
        formatted = "00:" + formatted;
    }
    
    return formatted;
}

export const getVideos = () => {
    return async (dispatch) => {
        const key = "AIzaSyAlVcclmKDZQO7a2EEkyg-W0ArSjxldqlo";
        const channel_id = "UCg8uNiuq_AslECnNZ0n4l8g";

        axios.get(`https://www.googleapis.com/youtube/v3/search?key=${key}&channelId=${channel_id}&part=snippet,id&order=date&maxResults=20`)
        .then(async res => {
            let videos = res.data.items;
            let result = [];

            await videos.forEach(async video => {
                await axios.get(`https://www.googleapis.com/youtube/v3/videos?part=statistics,contentDetails&id=${video.id.videoId}&key=${key}`)
                .then(res => {
                    result.push({...video, statistics: res.data.items[0].statistics, duration: formatISODate(res.data.items[0].contentDetails.duration)});
                })
            });

            dispatch(setVideos(result));
        })

    }
};