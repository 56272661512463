import React, { useEffect } from "react";
import { Link } from "react-scroll";

const NavMenu = ({ show, onClose }) => {
    return (
        show ?
            <div className="nav">
                <div className="nav__logo"onClick={onClose} >
                    <img loading="lazy" src={require("../../assets/logo99.svg").default} alt="logo" />
                </div>

                {/* <Link to="validators" className="nav__option" onClick={onClose}>Validators</Link> */}
                <Link to="faq" className="nav__option" onClick={onClose}>FAQ</Link>
                <a href="https://docs.osiris.finance" className="nav__option">DOCS <img className="option__image" src={require("../../assets/arrow.svg").default} /></a>
                <Link className="nav__option" to="contact" onClick={onClose}>Contact us</Link>

                <div className="button w-fit m-auto rounded-pill bg-black my-5">Stake Now</div>

                <div className="nav__close material-icons-outlined" onClick={onClose}>
                    close
                </div>
            </div>
            :
            <></>
    );
}

export default NavMenu;