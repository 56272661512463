import {
    GET_VIDEOS
} from '../actions/youtube';

import updateObject from "../updateObject"

const initialState = {
    videos: [],
};

export function set(state, action) {
    return updateObject(state, {
        videos: action.payload
    });
}

export function youtubeReducer(state = initialState, action) {
    switch (action.type) {
        case GET_VIDEOS: return set(state, action);
        default: return state;
    }
}